var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "justify-content": "flex-start",
        "align-items": "flex-start",
      },
    },
    [
      _vm.campo.tipoId == 1
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider", { staticClass: "py-1" }),
              _c("v-textarea", {
                attrs: {
                  label: _vm.campo.label,
                  counter: 1000,
                  maxlength: "1000",
                  dense: "",
                  outlined: "",
                  readonly: _vm.readonly,
                },
                on: {
                  blur: function ($event) {
                    return _vm.Salvar()
                  },
                },
                model: {
                  value: _vm.item.valor,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "valor", $$v)
                  },
                  expression: "item.valor",
                },
              }),
            ],
            1
          )
        : _vm.campo.tipoId == 2
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider", { staticClass: "py-1" }),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: _vm.campo.label,
                  dense: "",
                  outlined: "",
                  readonly: _vm.readonly,
                },
                on: {
                  blur: function ($event) {
                    return _vm.Salvar()
                  },
                },
                model: {
                  value: _vm.item.valorNumerico,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "valorNumerico", $$v)
                  },
                  expression: "item.valorNumerico",
                },
              }),
            ],
            1
          )
        : _vm.campo.tipoId == 3
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
                [_vm._v(_vm._s(_vm.campo.label))]
              ),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider"),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.campo.valores, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "mr-4",
                      attrs: { cols: "12", sm: "auto" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value: item.id,
                          label: item.valor,
                          dense: "",
                          readonly: _vm.readonly,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.SalvarCheckbox(item)
                          },
                        },
                        model: {
                          value: _vm.checkboxSelected,
                          callback: function ($$v) {
                            _vm.checkboxSelected = $$v
                          },
                          expression: "checkboxSelected",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm.campo.tipoId == 4
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
                [_vm._v(_vm._s(_vm.campo.label))]
              ),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider"),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.item.valorCampoId,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "valorCampoId", $$v)
                    },
                    expression: "item.valorCampoId",
                  },
                },
                _vm._l(_vm.campo.valores, function (item, index) {
                  return _c("v-radio", {
                    key: index,
                    attrs: {
                      label: item.valor,
                      value: item.id,
                      readonly: _vm.readonly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.Salvar()
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm.campo.tipoId == 5
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c(
                "span",
                {
                  staticClass: "ml-4",
                  staticStyle: { color: "red", "font-size": "14px" },
                },
                [_vm._v("Limite máximo de 5Mb.")]
              ),
              _c("v-divider", { staticClass: "py-1" }),
              _vm.item.valor
                ? _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      dense: "",
                      outlined: "",
                      "prepend-icon": "mdi-download",
                    },
                    on: {
                      "click:prepend": function ($event) {
                        return _vm.BaixarRespostaArquivo(
                          _vm.item.id,
                          _vm.item.valor
                        )
                      },
                    },
                    model: {
                      value: _vm.item.valor,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "valor", $$v)
                      },
                      expression: "item.valor",
                    },
                  })
                : _c("v-file-input", {
                    attrs: {
                      label: _vm.campo.label,
                      accept: ".pdf",
                      dense: "",
                      outlined: "",
                      disabled: _vm.readonly,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.Salvar()
                      },
                    },
                    model: {
                      value: _vm.arquivo,
                      callback: function ($$v) {
                        _vm.arquivo = $$v
                      },
                      expression: "arquivo",
                    },
                  }),
            ],
            1
          )
        : _vm.campo.tipoId == 6
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider", { staticClass: "py-1" }),
              _c("v-select", {
                attrs: {
                  label: _vm.campo.label,
                  items: _vm.campo.valores,
                  "item-text": "valor",
                  "item-value": "id",
                  dense: "",
                  outlined: "",
                  readonly: _vm.readonly,
                },
                on: {
                  blur: function ($event) {
                    return _vm.Salvar()
                  },
                },
                model: {
                  value: _vm.item.valorCampoId,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "valorCampoId", $$v)
                  },
                  expression: "item.valorCampoId",
                },
              }),
            ],
            1
          )
        : _vm.campo.tipoId == 7
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.campo.texto))]),
              _c("v-divider", { staticClass: "py-1" }),
              _c("v-text-field", {
                attrs: {
                  "prepend-icon": _vm.item.valor ? "mdi-web" : "",
                  label: _vm.campo.label,
                  counter: 255,
                  maxlength: "255",
                  dense: "",
                  outlined: "",
                  readonly: _vm.readonly,
                },
                on: {
                  "click:prepend": function ($event) {
                    return _vm.AbrirLink(_vm.item.valor)
                  },
                  blur: function ($event) {
                    return _vm.Salvar()
                  },
                },
                model: {
                  value: _vm.item.valor,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "valor", $$v)
                  },
                  expression: "item.valor",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
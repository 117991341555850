var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        persistent: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "pa-0",
              staticStyle: { position: "sticky", top: "0", "z-index": "1" },
            },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.Close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Cadastro Edital")]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      !_vm.preview &&
                      (_vm.item.id > 0 ||
                        (_vm.item.tipo == "D"
                          ? _vm.stepper == 4
                          : _vm.stepper == 5))
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                text: "",
                                disabled: !_vm.valid,
                                loading: _vm.salvando,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.Preview()
                                },
                              },
                            },
                            [_vm._v("Preview")]
                          )
                        : _vm._e(),
                      _vm.preview
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                text: "",
                                disabled: !_vm.valid,
                                loading: _vm.salvando,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.preview = false
                                },
                              },
                            },
                            [_vm._v("Voltar")]
                          )
                        : _vm._e(),
                      _vm.preview
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                text: "",
                                disabled: !_vm.valid,
                                loading: _vm.salvando,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.Publicar()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.item.status > 1
                                    ? "Republicar"
                                    : "Publicar"
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.preview,
                      expression: "!preview",
                    },
                  ],
                  staticClass: "mt-6 px-6",
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.item.id > 0,
                          label: "Tipo",
                          items: _vm.tipos,
                          "item-value": "id",
                          "item-text": "nome",
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.item.tipo,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "tipo", $$v)
                          },
                          expression: "item.tipo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.preview
                ? _c(
                    "v-stepper",
                    {
                      attrs: { vertical: "", flat: "" },
                      model: {
                        value: _vm.stepper,
                        callback: function ($$v) {
                          _vm.stepper = $$v
                        },
                        expression: "stepper",
                      },
                    },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          style: _vm.item.id > 0 ? "cursor: pointer" : "",
                          attrs: { step: "1", complete: _vm.stepper > 1 },
                          on: {
                            click: function ($event) {
                              return _vm.JumpTo(1)
                            },
                          },
                        },
                        [_vm._v("Geral")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formGeral",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Nome",
                                          rules: _vm.fieldRules,
                                          counter: 255,
                                          maxlength: "255",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.descricao,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "descricao", $$v)
                                          },
                                          expression: "item.descricao",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "Descrição",
                                          counter: 1000,
                                          maxlength: "1000",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.descricao2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "descricao2",
                                              $$v
                                            )
                                          },
                                          expression: "item.descricao2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "6",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "datetime-local",
                                          label: "Data/Hora Inicio",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.dataInicio,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "dataInicio",
                                              $$v
                                            )
                                          },
                                          expression: "item.dataInicio",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "6",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "datetime-local",
                                          label: "Data/Hora Fim",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.dataFim,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "dataFim", $$v)
                                          },
                                          expression: "item.dataFim",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.bairroComunidades,
                                          label: "Locais Execução",
                                          "item-value": "id",
                                          "item-text":
                                            "bairroComunidadeEndereco",
                                          multiple: "",
                                          chips: "",
                                          outlined: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "input-value":
                                                            data.selected,
                                                          close: "",
                                                        },
                                                        on: {
                                                          click: data.select,
                                                          "click:close":
                                                            function ($event) {
                                                              return _vm.RemoveAutoComplete(
                                                                data.item
                                                              )
                                                            },
                                                        },
                                                      },
                                                      "v-chip",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item
                                                              .bairroComunidadeEndereco
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2969162543
                                        ),
                                        model: {
                                          value: _vm.locais,
                                          callback: function ($$v) {
                                            _vm.locais = $$v
                                          },
                                          expression: "locais",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                        "align-items": "center",
                                      },
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled: !_vm.valid,
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Avancar()
                                            },
                                          },
                                        },
                                        [_vm._v("Continuar")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style: _vm.item.id > 0 ? "cursor: pointer" : "",
                          attrs: { step: "2", complete: _vm.stepper > 2 },
                          on: {
                            click: function ($event) {
                              return _vm.JumpTo(2)
                            },
                          },
                        },
                        [_vm._v(" Imagens / Regulamento ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formArquivos",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-around",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-2",
                                      staticStyle: { "border-radius": "10px" },
                                      style: _vm.item.logo
                                        ? "background-color: var(--v-imageBackground-base); margin-bottom: 25px"
                                        : "",
                                      attrs: { cols: "12", sm: "11", md: "5" },
                                    },
                                    [
                                      _vm.item.logo
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "flex",
                                                    "justify-content":
                                                      "flex-end",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.RemoveLogo()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-img", {
                                                attrs: {
                                                  src:
                                                    "data:image/jpeg;base64," +
                                                    _vm.item.logo,
                                                  width: "200px",
                                                  "max-height": "200px",
                                                  eager: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("v-file-input", {
                                                attrs: {
                                                  label: "Logo",
                                                  accept: ".jpg, .jpeg, .png",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.LoadLogo()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.logo,
                                                  callback: function ($$v) {
                                                    _vm.logo = $$v
                                                  },
                                                  expression: "logo",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Limite máximo de 5Mb."
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-2",
                                      staticStyle: { "border-radius": "10px" },
                                      style: _vm.item.banner
                                        ? "background-color: var(--v-imageBackground-base); margin-bottom: 25px"
                                        : "",
                                      attrs: { cols: "12", sm: "11", md: "5" },
                                    },
                                    [
                                      _vm.item.banner
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                                "flex-direction": "column",
                                                "border-radius": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "flex",
                                                    "justify-content":
                                                      "flex-end",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.RemoveBanner()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-img", {
                                                attrs: {
                                                  src:
                                                    "data:image/jpeg;base64," +
                                                    _vm.item.banner,
                                                  width: "550px",
                                                  "max-height": "200px",
                                                  eager: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("v-file-input", {
                                                attrs: {
                                                  label: "Banner",
                                                  accept: ".jpg, .jpeg, .png",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.LoadBanner()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.banner,
                                                  callback: function ($$v) {
                                                    _vm.banner = $$v
                                                  },
                                                  expression: "banner",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Limite máximo de 5Mb."
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mt-6 mb-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { "min-height": "30px" },
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _vm.item.regulamento
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: "Regulamento",
                                              rules: _vm.fieldRules,
                                              "prepend-icon": "mdi-download",
                                              clearable: "",
                                              dense: "",
                                              outlined: "",
                                              readonly: "",
                                            },
                                            on: {
                                              "click:prepend": function (
                                                $event
                                              ) {
                                                return _vm.Baixar()
                                              },
                                            },
                                            model: {
                                              value: _vm.item.regulamento,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "regulamento",
                                                  $$v
                                                )
                                              },
                                              expression: "item.regulamento",
                                            },
                                          })
                                        : _c("v-file-input", {
                                            attrs: {
                                              label: "Regulamento",
                                              accept: ".pdf",
                                              rules: _vm.fieldRules,
                                              dense: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.arquivoCarregar,
                                              callback: function ($$v) {
                                                _vm.arquivoCarregar = $$v
                                              },
                                              expression: "arquivoCarregar",
                                            },
                                          }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "14px",
                                          },
                                        },
                                        [_vm._v("Limite máximo de 5Mb.")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                        "align-items": "center",
                                      },
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "secondary mr-2",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Voltar()
                                            },
                                          },
                                        },
                                        [_vm._v("Voltar")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Avancar()
                                            },
                                          },
                                        },
                                        [_vm._v("Continuar")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style: _vm.item.id > 0 ? "cursor: pointer" : "",
                          attrs: { step: "3", complete: _vm.stepper > 3 },
                          on: {
                            click: function ($event) {
                              return _vm.JumpTo(3)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " Incentivos / Público Alvo / Tipo Empreendedor"
                          ),
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "fieldset",
                            { staticClass: "pa-2" },
                            [
                              _c("legend", [_vm._v("Incentivo")]),
                              _c(
                                "v-row",
                                { staticClass: "my-1" },
                                _vm._l(
                                  _vm.incentivos,
                                  function (incentivo, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: index,
                                        attrs: {
                                          cols: "12",
                                          sm: "6",
                                          md: "4",
                                          lg: "3",
                                        },
                                      },
                                      [
                                        _c("v-checkbox", {
                                          attrs: { label: incentivo.nome },
                                          on: {
                                            change: function ($event) {
                                              return _vm.OnIncentivoChange(
                                                incentivo
                                              )
                                            },
                                          },
                                          model: {
                                            value: incentivo.selected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                incentivo,
                                                "selected",
                                                $$v
                                              )
                                            },
                                            expression: "incentivo.selected",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "fieldset",
                            { staticClass: "mt-2 pa-2" },
                            [
                              _c("legend", [_vm._v("Público Alvo")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja selecionar os tipos de Público Alvo que poderão se inscrever no edital ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.hasPublicoAlvo,
                                          callback: function ($$v) {
                                            _vm.hasPublicoAlvo = $$v
                                          },
                                          expression: "hasPublicoAlvo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.hasPublicoAlvo
                                ? _c(
                                    "v-row",
                                    { staticClass: "my-1" },
                                    _vm._l(
                                      _vm.publicoAlvos,
                                      function (publico, index) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: index,
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: { label: publico.nome },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.OnPublicoChange(
                                                    publico
                                                  )
                                                },
                                              },
                                              model: {
                                                value: publico.selected,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    publico,
                                                    "selected",
                                                    $$v
                                                  )
                                                },
                                                expression: "publico.selected",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "fieldset",
                            { staticClass: "mt-2 pa-2" },
                            [
                              _c("legend", [_vm._v("Tipo Empreendedor")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja selecionar os Tipos de Empreendedores que poderão se inscrever no edital ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.hasTipoEmpreendedor,
                                          callback: function ($$v) {
                                            _vm.hasTipoEmpreendedor = $$v
                                          },
                                          expression: "hasTipoEmpreendedor",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.hasTipoEmpreendedor
                                ? _c(
                                    "v-row",
                                    { staticClass: "my-1" },
                                    _vm._l(
                                      _vm.empreendedores,
                                      function (empreendedor, index) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: index,
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                label: empreendedor.nome,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.OnEmpreendedorChange(
                                                    empreendedor
                                                  )
                                                },
                                              },
                                              model: {
                                                value: empreendedor.selected,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    empreendedor,
                                                    "selected",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "empreendedor.selected",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-4 mb-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end",
                                    "align-items": "center",
                                  },
                                  attrs: { cols: "12", sm: "12", md: "12" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "secondary mr-2",
                                        loading: _vm.salvando,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Voltar()
                                        },
                                      },
                                    },
                                    [_vm._v("Voltar")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.salvando,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Avancar()
                                        },
                                      },
                                    },
                                    [_vm._v("Continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style: _vm.item.id > 0 ? "cursor: pointer" : "",
                          attrs: { step: "4", complete: _vm.stepper > 4 },
                          on: {
                            click: function ($event) {
                              return _vm.JumpTo(4)
                            },
                          },
                        },
                        [_vm._v(" Documentos ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "my-1 pa-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: { cols: "12", sm: "12", md: "12" },
                                },
                                [
                                  _c("v-data-table", {
                                    staticClass: "mt-3 elevation-1",
                                    attrs: {
                                      headers: _vm.headerDocumento,
                                      items: _vm.item.documentos,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "formTipoDocumento",
                                                      attrs: {
                                                        "lazy-validation": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.validDocumento,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.validDocumento =
                                                            $$v
                                                        },
                                                        expression:
                                                          "validDocumento",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "px-4 pt-4 pb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "11",
                                                                md: "11",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.tipoDocumentos,
                                                                  rules:
                                                                    _vm.fieldRules,
                                                                  label:
                                                                    "Tipo Documento",
                                                                  "item-text":
                                                                    "nome",
                                                                  "item-value":
                                                                    "id",
                                                                  dense: "",
                                                                  outlined: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .editalTipoDocumento
                                                                      .tipoDocumentoId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.editalTipoDocumento,
                                                                        "tipoDocumentoId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "editalTipoDocumento.tipoDocumentoId",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "1",
                                                                md: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-2",
                                                                                    attrs:
                                                                                      {
                                                                                        fab: "",
                                                                                        small:
                                                                                          "",
                                                                                        depressed:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        dark: "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.AdicionarDocumento()
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-plus"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2595215553
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Adicionar"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "item.actions",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.ExcluirDocumento(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Excluir"),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-4",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end",
                                    "align-items": "center",
                                  },
                                  attrs: { cols: "12", sm: "12", md: "12" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "secondary mr-2",
                                        loading: _vm.salvando,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Voltar()
                                        },
                                      },
                                    },
                                    [_vm._v("Voltar")]
                                  ),
                                  _vm.item.tipo == "D"
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Salvar()
                                            },
                                          },
                                        },
                                        [_vm._v("Salvar")]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Avancar()
                                            },
                                          },
                                        },
                                        [_vm._v("Continuar")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.item.tipo == "P"
                        ? _c(
                            "v-stepper-step",
                            {
                              style: _vm.item.id > 0 ? "cursor: pointer" : "",
                              attrs: { step: "5", complete: _vm.stepper > 5 },
                              on: {
                                click: function ($event) {
                                  return _vm.JumpTo(5)
                                },
                              },
                            },
                            [_vm._v(" Personalizado ")]
                          )
                        : _vm._e(),
                      _vm.item.tipo == "P"
                        ? _c(
                            "v-stepper-content",
                            { staticClass: "pa-4 ma-0", attrs: { step: "5" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2 px-2",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja utilizar beneficiário padrão ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.item.isBeneficiarioPadrao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "isBeneficiarioPadrao",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.isBeneficiarioPadrao",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja utilizar equipe padrão ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.item.isEquipePadrao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "isEquipePadrao",
                                              $$v
                                            )
                                          },
                                          expression: "item.isEquipePadrao",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja utilizar indicadores padrão ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.item.isIndicadorPadrao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "isIndicadorPadrao",
                                              $$v
                                            )
                                          },
                                          expression: "item.isIndicadorPadrao",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Deseja utilizar orçamento padrão ?",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.item.isOrcamentoPadrao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "isOrcamentoPadrao",
                                              $$v
                                            )
                                          },
                                          expression: "item.isOrcamentoPadrao",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                { staticClass: "my-1 pa-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-6",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "flex-start",
                                      },
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mr-4",
                                          staticStyle: {
                                            color: "var(--v-secondary-base)",
                                            "font-size": "26px",
                                          },
                                        },
                                        [_vm._v(" Personalize seu formulário ")]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            fab: "",
                                                            small: "",
                                                            depressed: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.AdicionarFormulario()
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3172246497
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Adicionar Novo Formulário"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                      },
                                    },
                                    _vm._l(
                                      _vm.item.formularios,
                                      function (formulario, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "pa-2 mx-2 mt-2 mb-6",
                                                staticStyle: {
                                                  background: "#f0f0f0",
                                                },
                                                attrs: { flat: "" },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "6",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                rules:
                                                                  _vm.fieldRules,
                                                                counter: 45,
                                                                maxlength: "45",
                                                                dense: "",
                                                                "prepend-icon":
                                                                  "mdi-pencil",
                                                              },
                                                              model: {
                                                                value:
                                                                  formulario.nome,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      formulario,
                                                                      "nome",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formulario.nome",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-4",
                                                                        attrs: {
                                                                          fab: "",
                                                                          small:
                                                                            "",
                                                                          depressed:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.AdicionarCampo(
                                                                                formulario
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Adicionar Campo"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-4",
                                                                        attrs: {
                                                                          fab: "",
                                                                          small:
                                                                            "",
                                                                          depressed:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.ExcluirFormulario(
                                                                                formulario
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Excluir Formulário"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pa-0 pb-2" },
                                                  _vm._l(
                                                    formulario.campos,
                                                    function (campo, index) {
                                                      return _c(
                                                        "v-row",
                                                        {
                                                          key: index,
                                                          staticClass: "pa-4",
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              attrs: {
                                                                width: "100%",
                                                                flat: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card-title",
                                                                {
                                                                  staticClass:
                                                                    "pb-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "pt-2 pl-3",
                                                                          attrs:
                                                                            {
                                                                              cols: "10",
                                                                              sm: "10",
                                                                              md: "11",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "Texto do enunciado",
                                                                                  counter: 255,
                                                                                  maxlength:
                                                                                    "255",
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    campo.texto,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        campo,
                                                                                        "texto",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "campo.texto",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "flex",
                                                                              "align-items":
                                                                                "center",
                                                                              "justify-content":
                                                                                "flex-end",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              cols: "2",
                                                                              sm: "2",
                                                                              md: "1",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  bottom:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "activator",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        return [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-4",
                                                                                                attrs:
                                                                                                  {
                                                                                                    icon: "",
                                                                                                    "x-small":
                                                                                                      "",
                                                                                                    depressed:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.ExcluirCampo(
                                                                                                        formulario,
                                                                                                        campo
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "20px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "mdi-close"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Excluir"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-card-text",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "pt-2 px-2",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "12",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  rules:
                                                                                    _vm.fieldRules,
                                                                                  counter: 255,
                                                                                  maxlength:
                                                                                    "255",
                                                                                  dense:
                                                                                    "",
                                                                                  "prepend-icon":
                                                                                    "mdi-pencil",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    campo.label,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        campo,
                                                                                        "label",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "campo.label",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "12",
                                                                              sm: "12",
                                                                              md: "12",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  items:
                                                                                    _vm.tipoCampos,
                                                                                  label:
                                                                                    "Tipo",
                                                                                  "item-value":
                                                                                    "key",
                                                                                  "item-text":
                                                                                    "value",
                                                                                  rules:
                                                                                    _vm.fieldRules,
                                                                                  dense:
                                                                                    "",
                                                                                  filled:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    campo.tipoId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        campo,
                                                                                        "tipoId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "campo.tipoId",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  campo.tipoId ==
                                                                    3 ||
                                                                  campo.tipoId ==
                                                                    4 ||
                                                                  campo.tipoId ==
                                                                    6
                                                                    ? _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "ma-2",
                                                                          staticStyle:
                                                                            {
                                                                              background:
                                                                                "var(--v-toolbar-base)",
                                                                              "border-radius":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              dense:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pa-2",
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "flex-end",
                                                                                  "align-items":
                                                                                    "center",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      bottom:
                                                                                        "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "activator",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mr-4",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        fab: "",
                                                                                                        "x-small":
                                                                                                          "",
                                                                                                        depressed:
                                                                                                          "",
                                                                                                        color:
                                                                                                          "primary",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.AdicionarCampoValor(
                                                                                                            campo
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-plus"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Adicionar Nova Alternativa"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              campo.valores,
                                                                              function (
                                                                                valor,
                                                                                index
                                                                              ) {
                                                                                return _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    key: index,
                                                                                    staticClass:
                                                                                      "ml-6 my-2",
                                                                                    attrs:
                                                                                      {
                                                                                        dense:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            "justify-content":
                                                                                              "center",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            cols: "12",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mr-4",
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  "Alternativa: " +
                                                                                                  (index +
                                                                                                    1),
                                                                                                rules:
                                                                                                  _vm.fieldRules,
                                                                                                counter: 255,
                                                                                                maxlength:
                                                                                                  "255",
                                                                                                dense:
                                                                                                  "",
                                                                                                filled:
                                                                                                  "",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  valor.valor,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      valor,
                                                                                                      "valor",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "valor.valor",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                bottom:
                                                                                                  "",
                                                                                              },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "activator",
                                                                                                    fn: function (
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          _vm._g(
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "mr-4",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  icon: "",
                                                                                                                  "x-small":
                                                                                                                    "",
                                                                                                                  depressed:
                                                                                                                    "",
                                                                                                                  color:
                                                                                                                    "primary",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.ExcluirCampoValor(
                                                                                                                      campo,
                                                                                                                      valor
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "font-size":
                                                                                                                      "20px",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "mdi-close"
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Excluir"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-4",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                        "align-items": "center",
                                      },
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "secondary mr-2",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Voltar()
                                            },
                                          },
                                        },
                                        [_vm._v("Voltar")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.salvando,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Salvar(false)
                                            },
                                          },
                                        },
                                        [_vm._v("Salvar")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("div", [
                    _vm.item.tipo == "D"
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-stepper",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.preview,
                                    expression: "preview",
                                  },
                                ],
                                attrs: { vertical: "", flat: "" },
                                model: {
                                  value: _vm.stepper,
                                  callback: function ($$v) {
                                    _vm.stepper = $$v
                                  },
                                  expression: "stepper",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "py-4 px-8",
                                    staticStyle: {
                                      color: "var(--v-secondary-base)",
                                      "font-weight": "bold",
                                      "font-size": "18px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Confira como está o seu edital na visão dos seus proponentes."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v(
                                        "Se estiver como desejado, basta publicar."
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "1",
                                      complete: _vm.stepper > 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Organização")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "1" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label:
                                                  "Qual o objeto do seu estatuto?",
                                                counter: 5000,
                                                maxlength: "5000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label: "Conte a sua história",
                                                counter: 5000,
                                                maxlength: "5000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: { display: "flex" },
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "5",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "O gestor da organização é funcionário público? (Em caso positivo, mencionar o órgão)"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        readonly: "",
                                                        "item-value": "val2",
                                                        "item-text": "nome",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "7",
                                            },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                label: "Órgão público",
                                                counter: 60,
                                                maxlength: "60",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: { display: "flex" },
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "5",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Seu projeto está aprovado em Leis de Incentivos?"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        readonly: "",
                                                        "item-value": "val1",
                                                        "item-text": "nome",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "7",
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: { display: "flex" },
                                            attrs: {
                                              cols: "12",
                                              sm: "12",
                                              md: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "A sua organização pretende atuar no entorno de qual empresa do grupo?"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        readonly: "",
                                                        "item-value": "id",
                                                        "item-text": "nome",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "2",
                                      complete: _vm.stepper > 2,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" Projeto ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "2" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                label: "Nome",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                readonly: "",
                                                "item-text": "nome",
                                                "item-value": "id",
                                                label: "Área Atuação",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonlylabel:
                                                  "Identifique o problema que se pretende solucionar?",
                                                counter: 5000,
                                                maxlength: "5000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label: "Descreva o seu projeto",
                                                counter: 2000,
                                                maxlength: "2000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label:
                                                  "Qual a metodologia será utilizada no projeto?",
                                                counter: 2000,
                                                maxlength: "2000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                type: "number",
                                                label:
                                                  "Duração do projeto em meses",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Estado",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Município",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Bairro/Comunidade",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "3",
                                      complete: _vm.stepper > 3,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(3)
                                      },
                                    },
                                  },
                                  [_vm._v(" Benificiários ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "3" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label:
                                                  "Descreva o perfil do seu beneficiário",
                                                counter: 1000,
                                                maxlength: "1000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "O Projeto atende pessoas com deficiência?"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        readonly: "",
                                                        "item-value": "val2",
                                                        "item-text": "nome",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Número de beneficiários diretos"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        readonly: "",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-simple-table", {
                                              staticClass: "table",
                                              staticStyle: { width: "100%" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function () {
                                                      return [
                                                        _c("thead", [
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "var(--v-primary-base)",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                    color:
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Faixa Etária"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                    color:
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Quantidade"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("tbody", [
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v("0 a 4"),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v("5 a 9"),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "10 a 14"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "15 a 19"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "20 a 24"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "25 a 60"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "+ de 60"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              type: "number",
                                                                              dense:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                4218045693
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "4",
                                      complete: _vm.stepper > 4,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(4)
                                      },
                                    },
                                  },
                                  [_vm._v(" Equipe ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "4" } },
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerEquipe,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "5",
                                      complete: _vm.stepper > 5,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(5)
                                      },
                                    },
                                  },
                                  [_vm._v(" Indicadores ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "5" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                label:
                                                  "Qual o principal objetivo do seu projeto?",
                                                counter: 2000,
                                                maxlength: "2000",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerIndicador,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "6",
                                      complete: _vm.stepper > 6,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(6)
                                      },
                                    },
                                  },
                                  [_vm._v(" Divulgação ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "6" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "gray" } },
                                            [
                                              _vm._v(
                                                "Inclua todos os itens de divulgação do seu projeto. Você poderá incluir mais de um item."
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerComunicacao,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "7",
                                      complete: _vm.stepper > 7,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(7)
                                      },
                                    },
                                  },
                                  [_vm._v(" Orçamento ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "7" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "gray" } },
                                            [
                                              _vm._v(
                                                "O orçamento será cadastrado em dois níveis: no primeiro nível serão cadastradas as rubricas, no segundo nível serão incluídos os itens das rubricas e seus valores."
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "gray" } },
                                            [
                                              _vm._v(
                                                'Inclua a primeira rubrica, clique no botão "Novo". A rubrica irá aparecer na tabela abaixo, depois clique no + para incluir os itens da rubrica com os respectivos valores.'
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c("v-data-table", {
                                      staticClass: "elevation-1",
                                      attrs: {
                                        headers: _vm.headerOrcamento,
                                        "item-key": "index",
                                        "items-per-page": -1,
                                        "hide-default-footer": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "8",
                                      complete: _vm.stepper > 8,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(8)
                                      },
                                    },
                                  },
                                  [_vm._v(" Parceiros ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "8" } },
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerParceiro,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "9",
                                      complete: _vm.stepper > 9,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(9)
                                      },
                                    },
                                  },
                                  [_vm._v(" Documentos ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "9" } },
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerDocumentoP,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "10",
                                      complete: _vm.stepper > 10,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(10)
                                      },
                                    },
                                  },
                                  [_vm._v(" Vídeo ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "10" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "gray" },
                                              },
                                              [
                                                _vm._v(
                                                  " Grave um vídeo de até 2 minutos com o principal gestor da sua organização falando sobre este projeto. Coloque o vídeo em alguma plataforma online com acesso privado (youtube, vimeo,...). Inclua aqui o link do vídeo para que possa ser visualizado pela comissão avaliadora "
                                                ),
                                              ]
                                            ),
                                            _c("v-divider", {
                                              staticClass: "my-1",
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                label: "Link",
                                                "prepend-icon": _vm.item.video
                                                  ? "mdi-web"
                                                  : "",
                                                dense: "",
                                                outlined: "",
                                              },
                                              on: {
                                                "click:prepend": function (
                                                  $event
                                                ) {
                                                  return _vm.AbrirLink(
                                                    _vm.item.video
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "v-stepper",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.preview,
                                    expression: "preview",
                                  },
                                ],
                                attrs: { vertical: "", flat: "" },
                                model: {
                                  value: _vm.stepper,
                                  callback: function ($$v) {
                                    _vm.stepper = $$v
                                  },
                                  expression: "stepper",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "py-4 px-8",
                                    staticStyle: {
                                      color: "var(--v-secondary-base)",
                                      "font-weight": "bold",
                                      "font-size": "18px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Confira como está o seu edital na visão dos seus proponentes."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v(
                                        "Se estiver como desejado, basta publicar."
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "1",
                                      complete: _vm.stepper > 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Organização")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "1" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("span", [
                                              _vm._v("Nome: "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color:
                                                      "var(--v-secondary-base)",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("NOME DA ORGANIZAÇÃO")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: "2",
                                      complete: _vm.stepper > 2,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.JumpToP(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" Projeto ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "2" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-1",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                label: "Nome",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        !_vm.item.isOrcamentoPadrao
                                          ? _c(
                                              "v-row",
                                              {
                                                staticClass: "pa-1",
                                                attrs: { dense: "" },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "date",
                                                        label:
                                                          "Data Inicio (Projeto)",
                                                        readonly: "",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "date",
                                                        label:
                                                          "Data Término (Projeto)",
                                                        readonly: "",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        label:
                                                          "Valor Total (Projeto)",
                                                        readonly: "",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                readonly: "",
                                                "item-text": "nome",
                                                "item-value": "id",
                                                label: "Área Atuação",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Estado",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Município",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                readonly: "",
                                                "item-value": "id",
                                                "item-text": "nome",
                                                label: "Bairro/Comunidade",
                                                dense: "",
                                                outlined: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.item.isBeneficiarioPadrao
                                  ? _c(
                                      "v-stepper-step",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          step: "3",
                                          complete: _vm.stepper > 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.JumpToP(3)
                                          },
                                        },
                                      },
                                      [_vm._v(" Benificiários ")]
                                    )
                                  : _vm._e(),
                                _vm.item.isBeneficiarioPadrao
                                  ? _c(
                                      "v-stepper-content",
                                      { attrs: { step: "3" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "py-1",
                                            attrs: { dense: "" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" },
                                              },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    readonly: "",
                                                    label:
                                                      "Descreva o perfil do seu beneficiário",
                                                    counter: 1000,
                                                    maxlength: "1000",
                                                    dense: "",
                                                    outlined: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "O Projeto atende pessoas com deficiência?"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            readonly: "",
                                                            "item-value":
                                                              "val2",
                                                            "item-text": "nome",
                                                            dense: "",
                                                            outlined: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Número de beneficiários diretos"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "number",
                                                            readonly: "",
                                                            dense: "",
                                                            outlined: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-simple-table", {
                                                  staticClass: "table",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function () {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "var(--v-primary-base)",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-weight":
                                                                            "bold",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Faixa Etária"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-weight":
                                                                            "bold",
                                                                          color:
                                                                            "white",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Quantidade"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("tbody", [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "0 a 4"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "5 a 9"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "10 a 14"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "15 a 19"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "20 a 24"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "25 a 60"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "+ de 60"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  readonly:
                                                                                    "",
                                                                                  type: "number",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4218045693
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.item.isEquipePadrao
                                  ? _c(
                                      "v-stepper-step",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 1),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.JumpToP(
                                              _vm.CheckPosition(_vm.item, 1)
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Equipe ")]
                                    )
                                  : _vm._e(),
                                _vm.item.isEquipePadrao
                                  ? _c(
                                      "v-stepper-content",
                                      {
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 1),
                                        },
                                      },
                                      [
                                        _c("v-data-table", {
                                          attrs: {
                                            headers: _vm.headerEquipe,
                                            "hide-default-footer": "",
                                            "items-per-page": -1,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.item.isIndicadorPadrao
                                  ? _c(
                                      "v-stepper-step",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 2),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.JumpToP(
                                              _vm.CheckPosition(_vm.item, 2)
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Indicadores ")]
                                    )
                                  : _vm._e(),
                                _vm.item.isIndicadorPadrao
                                  ? _c(
                                      "v-stepper-content",
                                      {
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 2),
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "py-1",
                                            attrs: { dense: "" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    readonly: "",
                                                    label:
                                                      "Qual o principal objetivo do seu projeto?",
                                                    counter: 2000,
                                                    maxlength: "2000",
                                                    dense: "",
                                                    outlined: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-data-table", {
                                          attrs: {
                                            headers: _vm.headerIndicador,
                                            "hide-default-footer": "",
                                            "items-per-page": -1,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.item.isOrcamentoPadrao
                                  ? _c(
                                      "v-stepper-step",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 3),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.JumpToP(
                                              _vm.CheckPosition(_vm.item, 3)
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Orçamento ")]
                                    )
                                  : _vm._e(),
                                _vm.item.isOrcamentoPadrao
                                  ? _c(
                                      "v-stepper-content",
                                      {
                                        attrs: {
                                          step: _vm.CheckPosition(_vm.item, 3),
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "gray",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "O orçamento será cadastrado em dois níveis: no primeiro nível serão cadastradas as rubricas, no segundo nível serão incluídos os itens das rubricas e seus valores."
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "gray",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      'Inclua a primeira rubrica, clique no botão "Novo". A rubrica irá aparecer na tabela abaixo, depois clique no + para incluir os itens da rubrica com os respectivos valores.'
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider"),
                                        _c("v-data-table", {
                                          staticClass: "elevation-1",
                                          attrs: {
                                            headers: _vm.headerOrcamento,
                                            "item-key": "index",
                                            "items-per-page": -1,
                                            "hide-default-footer": "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-stepper-step",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      step: _vm.CheckPosition(_vm.item, 4),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.JumpToP(
                                          _vm.CheckPosition(_vm.item, 4)
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Documentos ")]
                                ),
                                _c(
                                  "v-stepper-content",
                                  {
                                    attrs: {
                                      step: _vm.CheckPosition(_vm.item, 4),
                                    },
                                  },
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headerDocumentoP,
                                        "hide-default-footer": "",
                                        "items-per-page": -1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.item.formularios,
                                  function (formulario, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "v-stepper-step",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              step:
                                                _vm.CheckPosition(_vm.item, 4) +
                                                1 +
                                                index,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.JumpToP(
                                                  _vm.CheckPosition(
                                                    _vm.item,
                                                    4
                                                  ) +
                                                    1 +
                                                    index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(formulario.nome))]
                                        ),
                                        _c(
                                          "v-stepper-content",
                                          {
                                            attrs: {
                                              step:
                                                _vm.CheckPosition(_vm.item, 4) +
                                                1 +
                                                index,
                                            },
                                          },
                                          _vm._l(
                                            formulario.campos,
                                            function (campo, index) {
                                              return _c(
                                                "v-row",
                                                {
                                                  key: index,
                                                  staticClass: "pa-4",
                                                  attrs: { dense: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "projeto-personalizavel-resposta",
                                                        {
                                                          attrs: {
                                                            campo: campo,
                                                            readonly: true,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        persistent: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "pa-0",
              staticStyle: { position: "sticky", top: "0", "z-index": "1" },
            },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.Close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(
                    "v-toolbar-title",
                    { staticStyle: { width: "100%", display: "flex" } },
                    [
                      _c("span", [_vm._v("Cadastro Projeto")]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-evenly",
                          },
                        },
                        [_vm._v(_vm._s(_vm.titulo))]
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _vm.concordo
                    ? _c(
                        "v-toolbar-items",
                        [
                          _vm.item.passoAtual > 1 &&
                          (_vm.item.status == 1 || _vm.item.status == 3)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dark: "",
                                    text: "",
                                    disabled: !_vm.valid,
                                    loading: _vm.salvando,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Voltar()
                                    },
                                  },
                                },
                                [_vm._v("Voltar")]
                              )
                            : _vm._e(),
                          _vm.item.status == 1 || _vm.item.status == 3
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dark: "",
                                    text: "",
                                    disabled: !_vm.valid,
                                    loading: _vm.salvando,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.item.passoAtual
                                        ? _vm.Avancar()
                                        : _vm.Enviar()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.item.passoAtual != 10
                                        ? "Avançar"
                                        : "Enviar"
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { class: _vm.$vuetify.breakpoint.smAndDown ? "pa-0" : "py-0" },
            [
              !_vm.concordo
                ? _c(
                    "div",
                    { staticClass: "pt-2" },
                    [
                      _c("div", { staticClass: "pa-2" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "22px",
                              color: "black",
                            },
                          },
                          [_vm._v("Regulamento - ")]
                        ),
                        _c("span", { staticStyle: { "font-size": "18px" } }, [
                          _vm._v("Informações sobre o regulamento."),
                        ]),
                      ]),
                      _c("v-divider", { staticClass: "py-2" }),
                      _vm.editalRegulamento.length > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "75vh",
                                "overflow-y": "auto",
                              },
                            },
                            [
                              _c("vue-pdf-app", {
                                ref: "pdf",
                                attrs: {
                                  pdf:
                                    "data:application/pdf;base64," +
                                    _vm.editalRegulamento,
                                  theme: "light",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "mt-2",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "center",
                            "align-items": "flex-start",
                          },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Eu li e aceito o termo acima.",
                              dense: "",
                            },
                            model: {
                              value: _vm.editalConcordo,
                              callback: function ($$v) {
                                _vm.editalConcordo = $$v
                              },
                              expression: "editalConcordo",
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: { color: "error" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Close()
                                    },
                                  },
                                },
                                [_vm._v("Não Concordo")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "success",
                                    disabled: !_vm.editalConcordo,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.concordo = true
                                    },
                                  },
                                },
                                [_vm._v("Concordo")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-stepper",
                    {
                      attrs: { vertical: "", flat: "" },
                      model: {
                        value: _vm.item.passoAtual,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "passoAtual", $$v)
                        },
                        expression: "item.passoAtual",
                      },
                    },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "1" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(1)
                            },
                          },
                        },
                        [_vm._v("Organização")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formOrganizacao",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-1", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label:
                                            "Qual o objeto do seu estatuto?",
                                          rules: _vm.fieldRules,
                                          counter: 5000,
                                          maxlength: "5000",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.objetoSocialEstatuto,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "objetoSocialEstatuto",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.objetoSocialEstatuto",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label: "Conte a sua história",
                                          rules: _vm.fieldRules,
                                          counter: 5000,
                                          maxlength: "5000",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.suaHistoria,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "suaHistoria",
                                              $$v
                                            )
                                          },
                                          expression: "item.suaHistoria",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { display: "flex" },
                                      attrs: { cols: "12", sm: "6", md: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "O gestor da organização é funcionário público? (Em caso positivo, mencionar o órgão)"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  readonly: _vm.readOnly,
                                                  items: _vm.enumSimNao,
                                                  "item-value": "val2",
                                                  "item-text": "nome",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item
                                                      .isFuncionarioPublico,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "isFuncionarioPublico",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isFuncionarioPublico",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          disabled:
                                            _vm.item.isFuncionarioPublico == 0,
                                          label: "Órgão público",
                                          rules:
                                            _vm.item.isFuncionarioPublico == 1
                                              ? _vm.fieldRules
                                              : [],
                                          counter: 60,
                                          maxlength: "60",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value:
                                            _vm.item.orgaoFuncionarioPublico,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "orgaoFuncionarioPublico",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.orgaoFuncionarioPublico",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { display: "flex" },
                                      attrs: { cols: "12", sm: "6", md: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Seu projeto está aprovado em Leis de Incentivos?"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  readonly: _vm.readOnly,
                                                  items: _vm.enumSimNao,
                                                  "item-value": "val1",
                                                  "item-text": "nome",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item.isLeiIncentivo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "isLeiIncentivo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isLeiIncentivo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6", md: "7" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          disabled: !_vm.item.isLeiIncentivo,
                                          items: _vm.leisIncentivo,
                                          rules: _vm.item.isLeiIncentivo
                                            ? _vm.fieldRules
                                            : [],
                                          "item-value": "id",
                                          "item-text": "nome",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.leiIncentivo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "leiIncentivo",
                                              $$v
                                            )
                                          },
                                          expression: "item.leiIncentivo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { display: "flex" },
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "A sua organização pretende atuar no entorno de qual empresa do grupo?"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  readonly: _vm.readOnly,
                                                  items: _vm.grupos,
                                                  "item-value": "id",
                                                  "item-text": "nome",
                                                  rules: _vm.fieldRules,
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.item.grupoId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "grupoId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.grupoId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "2" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(2)
                            },
                          },
                        },
                        [_vm._v("Projeto")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formProjeto",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-1", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label: "Nome",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.nome,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "nome", $$v)
                                          },
                                          expression: "item.nome",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          items: _vm.areasAtuacao,
                                          "item-text": "nome",
                                          "item-value": "id",
                                          label: "Área Atuação",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.areaAtuacaoId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "areaAtuacaoId",
                                              $$v
                                            )
                                          },
                                          expression: "item.areaAtuacaoId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label:
                                            "Identifique o problema que se pretende solucionar?",
                                          counter: 5000,
                                          maxlength: "5000",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.problemaSolucionar,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "problemaSolucionar",
                                              $$v
                                            )
                                          },
                                          expression: "item.problemaSolucionar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label: "Descreva o seu projeto",
                                          counter: 2000,
                                          maxlength: "2000",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.qualSeuSonho,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "qualSeuSonho",
                                              $$v
                                            )
                                          },
                                          expression: "item.qualSeuSonho",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label:
                                            "Qual a metodologia será utilizada no projeto?",
                                          counter: 2000,
                                          maxlength: "2000",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.metodologiaUtilizada,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "metodologiaUtilizada",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.metodologiaUtilizada",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          type: "number",
                                          label: "Duração do projeto em meses",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.duracaoMeses,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "duracaoMeses",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "item.duracaoMeses",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          items: _vm.estados,
                                          "item-value": "id",
                                          "item-text": "nome",
                                          label: "Estado",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.estadoId,
                                          callback: function ($$v) {
                                            _vm.estadoId = $$v
                                          },
                                          expression: "estadoId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          items: _vm.municipios,
                                          "item-value": "id",
                                          "item-text": "nome",
                                          label: "Município",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.municipioId,
                                          callback: function ($$v) {
                                            _vm.municipioId = $$v
                                          },
                                          expression: "municipioId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          items: _vm.bairroComunidades,
                                          "item-value": "id",
                                          "item-text": "nome",
                                          label: "Bairro/Comunidade",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.bairroComunidadeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "bairroComunidadeId",
                                              $$v
                                            )
                                          },
                                          expression: "item.bairroComunidadeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "3" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(3)
                            },
                          },
                        },
                        [_vm._v("Benificiários")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formBeneficiario",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-1", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label:
                                            "Descreva o perfil do seu beneficiário",
                                          counter: 1000,
                                          maxlength: "1000",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.perfilBeneficiario,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "perfilBeneficiario",
                                              $$v
                                            )
                                          },
                                          expression: "item.perfilBeneficiario",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "O Projeto atende pessoas com deficiência?"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  readonly: _vm.readOnly,
                                                  items: _vm.enumSimNao,
                                                  "item-value": "val2",
                                                  "item-text": "nome",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item
                                                      .isTrabalhaDeficiencia,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "isTrabalhaDeficiencia",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isTrabalhaDeficiencia",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Número de beneficiários diretos"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  readonly: "",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.totalBeneficiarios,
                                                  callback: function ($$v) {
                                                    _vm.totalBeneficiarios =
                                                      _vm._n($$v)
                                                  },
                                                  expression:
                                                    "totalBeneficiarios",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function () {
                                              return [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "var(--v-primary-base)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            color: "white",
                                                          },
                                                        },
                                                        [_vm._v("Faixa Etária")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            color: "white",
                                                          },
                                                        },
                                                        [_vm._v("Quantidade")]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("tbody", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("0 a 4"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria0A4,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria0A4",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria0A4",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("5 a 9"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria5A9,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria5A9",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria5A9",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("10 a 14"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria10A14,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria10A14",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria10A14",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("15 a 19"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria15A19,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria15A19",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria15A19",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("20 a 24"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria20A24,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria20A24",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria20A24",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("25 a 60"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria25A60,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria25A60",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria25A60",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                      },
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("+ de 60"),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      _vm.readOnly,
                                                                    type: "number",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .faixaEtaria60,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "faixaEtaria60",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.faixaEtaria60",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "4" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(4)
                            },
                          },
                        },
                        [_vm._v("Equipe")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.item.equipes,
                              headers: _vm.headerEquipe,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-spacer"),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    fab: "",
                                                                    small: "",
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarEquipe()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    551597468
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Novo")])]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AdicionarEquipe(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-pencil")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Editar")])]
                                          )
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ExcluirEquipe(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Excluir")])]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [_vm._v(_vm._s(index + 1))]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.formaContratacao",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.formaContratacaoStr
                                              ? item.formaContratacaoStr
                                              : _vm.formas.find(function (x) {
                                                  return (
                                                    x.id ==
                                                    item.formaContratacao
                                                  )
                                                }).nome
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "5" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(5)
                            },
                          },
                        },
                        [_vm._v("Indicadores")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "5" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formIndicador",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-1", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label:
                                            "Qual o principal objetivo do seu projeto?",
                                          counter: 2000,
                                          maxlength: "2000",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.item.objetivo,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "objetivo", $$v)
                                          },
                                          expression: "item.objetivo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                attrs: {
                                  items: _vm.item.indicadores,
                                  headers: _vm.headerIndicador,
                                  "hide-default-footer": "",
                                  "items-per-page": -1,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "top",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-toolbar",
                                            { attrs: { flat: "" } },
                                            [
                                              _c("v-spacer"),
                                              !_vm.readOnly
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        fab: "",
                                                                        small:
                                                                          "",
                                                                        depressed:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        dark: "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.AdicionarIndicador()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1600902768
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Novo"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarIndicador(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-pencil"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Editar")])]
                                              )
                                            : _vm._e(),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.ExcluirIndicador(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Excluir"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.id",
                                      fn: function (ref) {
                                        var index = ref.index
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(index + 1)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "6" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(6)
                            },
                          },
                        },
                        [_vm._v("Divulgação")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "6" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("span", { staticStyle: { color: "gray" } }, [
                                  _vm._v(
                                    "Inclua todos os itens de divulgação do seu projeto. Você poderá incluir mais de um item."
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.item.comunicacoes,
                              headers: _vm.headerComunicacao,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-spacer"),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    fab: "",
                                                                    small: "",
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarComunicacao()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3358147229
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Novo")])]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AdicionarComunicacao(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-pencil")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Editar")])]
                                          )
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ExcluirComunicacao(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Excluir")])]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [_vm._v(_vm._s(index + 1))]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "7" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(7)
                            },
                          },
                        },
                        [_vm._v("Orçamento")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "7" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("span", { staticStyle: { color: "gray" } }, [
                                  _vm._v(
                                    "O orçamento será cadastrado em dois níveis: no primeiro nível serão cadastradas as rubricas, no segundo nível serão incluídos os itens das rubricas e seus valores."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c("span", { staticStyle: { color: "gray" } }, [
                                  _vm._v(
                                    'Inclua a primeira rubrica, clique no botão "Novo". A rubrica irá aparecer na tabela abaixo, depois clique no + para incluir os itens da rubrica com os respectivos valores.'
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headerOrcamento,
                              items: _vm.item.orcamentos,
                              "item-key": "index",
                              expanded: _vm.item.orcamentos,
                              "show-expand": "",
                              "items-per-page": -1,
                              "hide-default-footer": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-spacer"),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarOrcamento()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " Novo "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2337213989
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Criar Rubrica"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AdicionarOrcamentoItem(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-plus-circle"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Novo Item")])]
                                          )
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AdicionarOrcamento(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-pencil")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Editar")])]
                                          )
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ExcluirOrcamento(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Excluir")])]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [_vm._v(_vm._s(index + 1))]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.dataInicio",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.dataInicio.toDateDDMMYYYY()
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.dataTermino",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.dataTermino.toDateDDMMYYYY()
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.valorTotal",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.valorTotal
                                              ? item.valorTotal.toMoeda()
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "expanded-item",
                                  fn: function (ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { colspan: headers.length },
                                        },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "expandDataTable",
                                            attrs: {
                                              headers: _vm.headerOrcamentoItem,
                                              items: item.itens,
                                              "item-key": "id",
                                              "items-per-page": -1,
                                              "hide-default-footer": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.actions",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      !_vm.readOnly
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.AdicionarOrcamentoItem(
                                                                                        null,
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-pencil"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Editar"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.readOnly
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.ExcluirOrcamentoItem(
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-delete"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Excluir"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.paiIndex",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.paiIndex + 1
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.id",
                                                  fn: function (ref) {
                                                    var index = ref.index
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.dataInicio",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.dataInicio.toDateDDMMYYYY()
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.dataTermino",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.dataTermino.toDateDDMMYYYY()
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.totalEstimado",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.totalEstimado.toMoeda()
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "8" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(8)
                            },
                          },
                        },
                        [_vm._v("Parceiros")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "8" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.item.parceiros,
                              headers: _vm.headerParceiro,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-spacer"),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    fab: "",
                                                                    small: "",
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarParceiro()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    252450480
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Novo")])]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AdicionarParceiro(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-pencil")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Editar")])]
                                          )
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ExcluirParceiro(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Excluir")])]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [_vm._v(_vm._s(index + 1))]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.tipoContribuicao",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.tipoContribuicaoStr
                                              ? item.tipoContribuicaoStr
                                              : _vm.contribuicoes.find(
                                                  function (x) {
                                                    return (
                                                      x.id ==
                                                      item.tipoContribuicao
                                                    )
                                                  }
                                                ).nome
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.valor",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(item.valor.toMoeda())),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "9" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(9)
                            },
                          },
                        },
                        [_vm._v("Documentos")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "9" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.item.documentos,
                              headers: _vm.headerDocumento,
                              "item-class": _vm.itemExcluido,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-spacer"),
                                          !_vm.readOnly
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    fab: "",
                                                                    small: "",
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.AdicionarDocumento()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2595215553
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Novo")])]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      !_vm.readOnly
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                disabled:
                                                                  item.excluir,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ExcluirDocumento(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Excluir")])]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [_vm._v(_vm._s(index + 1))]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.nome",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      item.id > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "blue",
                                                "text-decoration": "underline",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.BaixarDocumento(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.nome))]
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(item.nome)),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        {
                          style:
                            _vm.item.status == 1 || _vm.item.status == 3
                              ? ""
                              : "cursor: pointer",
                          attrs: { step: "10" },
                          on: {
                            click: function ($event) {
                              _vm.item.status == 1 || _vm.item.status == 3
                                ? ""
                                : _vm.JumpTo(10)
                            },
                          },
                        },
                        [_vm._v("Vídeo")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "10" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formVideo",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "gray" } },
                                        [
                                          _vm._v(
                                            " Grave um vídeo de até 2 minutos com o principal gestor da sua organização falando sobre este projeto. Coloque o vídeo em alguma plataforma online com acesso privado (youtube, vimeo,...). Inclua aqui o link do vídeo para que possa ser visualizado pela comissão avaliadora "
                                          ),
                                        ]
                                      ),
                                      _c("v-divider", { staticClass: "my-1" }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: _vm.readOnly,
                                          label: "Link",
                                          "prepend-icon": _vm.item.video
                                            ? "mdi-web"
                                            : "",
                                          rules: _vm.fieldRules,
                                          dense: "",
                                          outlined: "",
                                        },
                                        on: {
                                          "click:prepend": function ($event) {
                                            return _vm.AbrirLink(_vm.item.video)
                                          },
                                        },
                                        model: {
                                          value: _vm.item.video,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "video", $$v)
                                          },
                                          expression: "item.video",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cadastro-projeto-equipe", {
        attrs: { item: _vm.equipe },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarEquipe = false
          },
          salvou: function ($event) {
            return _vm.SalvarEquipe()
          },
        },
        model: {
          value: _vm.dialogAdicionarEquipe,
          callback: function ($$v) {
            _vm.dialogAdicionarEquipe = $$v
          },
          expression: "dialogAdicionarEquipe",
        },
      }),
      _c("cadastro-projeto-indicador", {
        attrs: { item: _vm.indicador },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarIndicador = false
          },
          salvou: function ($event) {
            return _vm.SalvarIndicador()
          },
        },
        model: {
          value: _vm.dialogAdicionarIndicador,
          callback: function ($$v) {
            _vm.dialogAdicionarIndicador = $$v
          },
          expression: "dialogAdicionarIndicador",
        },
      }),
      _c("cadastro-projeto-comunicacao", {
        attrs: { item: _vm.comunicacao },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarComunicacao = false
          },
          salvou: function ($event) {
            return _vm.SalvarComunicacao()
          },
        },
        model: {
          value: _vm.dialogAdicionarComunicacao,
          callback: function ($$v) {
            _vm.dialogAdicionarComunicacao = $$v
          },
          expression: "dialogAdicionarComunicacao",
        },
      }),
      _c("cadastro-projeto-parceiro", {
        attrs: { item: _vm.parceiro },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarParceiro = false
          },
          salvou: function ($event) {
            return _vm.SalvarParceiro()
          },
        },
        model: {
          value: _vm.dialogAdicionarParceiro,
          callback: function ($$v) {
            _vm.dialogAdicionarParceiro = $$v
          },
          expression: "dialogAdicionarParceiro",
        },
      }),
      _c("cadastro-projeto-documento", {
        attrs: { editalId: _vm.item.editalId, item: _vm.documento },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarDocumento = false
          },
          salvou: function ($event) {
            return _vm.SalvarDocumento()
          },
        },
        model: {
          value: _vm.dialogAdicionarDocumento,
          callback: function ($$v) {
            _vm.dialogAdicionarDocumento = $$v
          },
          expression: "dialogAdicionarDocumento",
        },
      }),
      _c("cadastro-projeto-orcamento", {
        attrs: { item: _vm.orcamento },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarOrcamento = false
          },
          salvou: function ($event) {
            return _vm.SalvarOrcamento()
          },
        },
        model: {
          value: _vm.dialogAdicionarOrcamento,
          callback: function ($$v) {
            _vm.dialogAdicionarOrcamento = $$v
          },
          expression: "dialogAdicionarOrcamento",
        },
      }),
      _c("cadastro-projeto-orcamento-item", {
        attrs: { item: _vm.orcamentoItem },
        on: {
          fechou: function ($event) {
            _vm.dialogAdicionarOrcamentoItem = false
          },
          salvou: function ($event) {
            return _vm.SalvarOrcamentoItem()
          },
        },
        model: {
          value: _vm.dialogAdicionarOrcamentoItem,
          callback: function ($$v) {
            _vm.dialogAdicionarOrcamentoItem = $$v
          },
          expression: "dialogAdicionarOrcamentoItem",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
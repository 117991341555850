import { render, staticRenderFns } from "./MasterPage.vue?vue&type=template&id=e8b40a8a&"
import script from "./MasterPage.vue?vue&type=script&lang=ts&"
export * from "./MasterPage.vue?vue&type=script&lang=ts&"
import style0 from "./MasterPage.vue?vue&type=style&index=0&id=e8b40a8a&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VAvatar,VBtn,VContainer,VDivider,VFooter,VIcon,VImg,VList,VListGroup,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VMenu,VNavigationDrawer,VSpacer,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/Users/renan/Fontes/OngFacil/Edital-Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e8b40a8a')) {
      api.createRecord('e8b40a8a', component.options)
    } else {
      api.reload('e8b40a8a', component.options)
    }
    module.hot.accept("./MasterPage.vue?vue&type=template&id=e8b40a8a&", function () {
      api.rerender('e8b40a8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/MasterPage.vue"
export default component.exports
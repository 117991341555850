var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        persistent: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "pa-0",
              staticStyle: { position: "sticky", top: "0", "z-index": "10" },
            },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.Close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Cadastro Avaliação Projeto")]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _vm.item.veredito != null
                        ? _c(
                            "v-btn",
                            {
                              attrs: { dark: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.Reabrir()
                                },
                              },
                            },
                            [_vm._v("Reabrir")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", text: "", disabled: !_vm.valid },
                          on: {
                            click: function ($event) {
                              return _vm.Salvar()
                            },
                          },
                        },
                        [_vm._v("Salvar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-2" },
            [
              _c(
                "v-row",
                { staticClass: "mt-2" },
                [
                  _vm.projeto.id > 0
                    ? _c(
                        "v-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            "overflow-y": "auto",
                            height: "92vh",
                          },
                          attrs: { cols: "12", sm: "12", md: "6", lg: "7" },
                        },
                        [
                          _c(
                            "v-stepper",
                            {
                              attrs: { vertical: "", flat: "" },
                              model: {
                                value: _vm.stepper,
                                callback: function ($$v) {
                                  _vm.stepper = $$v
                                },
                                expression: "stepper",
                              },
                            },
                            [
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(1)
                                    },
                                  },
                                },
                                [_vm._v("Organização")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "1" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Qual o objeto do seu estatuto?")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto.objetoSocialEstatuto
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Conte a sua história.")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.projeto.suaHistoria)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "O gestor da organização é funcionário público? (Em caso positivo, mencionar o órgão)"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto
                                                    .isFuncionarioPublico == 1
                                                    ? "Sim"
                                                    : "Não"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      ),
                                      _vm.projeto.orgaoFuncionarioPublico
                                        .length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "py-2 pa-4",
                                              staticStyle: {
                                                background:
                                                  "var(--v-toolbar-base)",
                                                "border-radius": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.projeto
                                                        .orgaoFuncionarioPublico
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Seu projeto está aprovado em Leis de Incentivos?"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto.isLeiIncentivo
                                                    ? "Sim"
                                                    : "Não"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      ),
                                      _vm.projeto.isLeiIncentivo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "py-2 pa-4",
                                              staticStyle: {
                                                background:
                                                  "var(--v-toolbar-base)",
                                                "border-radius": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.leisIncentivo.length >
                                                        0
                                                        ? _vm.leisIncentivo.find(
                                                            function (x) {
                                                              return (
                                                                x.id ==
                                                                _vm.projeto
                                                                  .leiIncentivo
                                                              )
                                                            }
                                                          ).nome
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "A sua organização pretende atuar no entorno de qual empresa do grupo?"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto.grupo
                                                    ? _vm.projeto.grupo.nome
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(2)
                                    },
                                  },
                                },
                                [_vm._v("Projeto")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "2" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Nome")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.projeto.nome))]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Área Atuação")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto.areaAtuacao
                                                ? _vm.projeto.areaAtuacao.nome
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Identifique o problema que se pretende solucionar?"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto.problemaSolucionar
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Descreva o seu projeto")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.projeto.qualSeuSonho)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Qual a metodologia será utilizada no projeto?"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto.metodologiaUtilizada
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Duração do projeto em meses")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.projeto.duracaoMeses)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Estado")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm.projeto.bairroComunidade
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto.bairroComunidade
                                                    .estado
                                                    ? _vm.projeto
                                                        .bairroComunidade.estado
                                                        .nome
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Município")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm.projeto.bairroComunidade
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto.bairroComunidade
                                                    .municipio
                                                    ? _vm.projeto
                                                        .bairroComunidade
                                                        .municipio.nome
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Bairro/Comunidade")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm.projeto.bairroComunidade
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pa-4",
                                          staticStyle: {
                                            background: "var(--v-toolbar-base)",
                                            "border-radius": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projeto.bairroComunidade
                                                    ? _vm.projeto
                                                        .bairroComunidade.nome
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "3" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(3)
                                    },
                                  },
                                },
                                [_vm._v("Beneficiários")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "3" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Descreva o perfil do seu beneficiário"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto.perfilBeneficiario
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "O Projeto atende pessoas com deficiência?"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projeto
                                                .isTrabalhaDeficiencia == 1
                                                ? "Sim"
                                                : "Não"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Número de beneficiários diretos")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.totalBeneficiarios))]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Faixa Etária"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Quantidade")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("tbody", [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("0 a 4"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.projeto
                                                              .faixaEtaria0A4
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-toolbar-base)",
                                                        },
                                                      },
                                                      [
                                                        _c("td", [
                                                          _vm._v("5 a 9"),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.projeto
                                                                .faixaEtaria5A9
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("10 a 14"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.projeto
                                                              .faixaEtaria10A14
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-toolbar-base)",
                                                        },
                                                      },
                                                      [
                                                        _c("td", [
                                                          _vm._v("15 a 19"),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.projeto
                                                                .faixaEtaria15A19
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("20 a 24"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.projeto
                                                              .faixaEtaria20A24
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-toolbar-base)",
                                                        },
                                                      },
                                                      [
                                                        _c("td", [
                                                          _vm._v("25 a 60"),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.projeto
                                                                .faixaEtaria25A60
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("+ de 60"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.projeto
                                                              .faixaEtaria60
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          420436205
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "4" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(4)
                                    },
                                  },
                                },
                                [_vm._v("Equipe")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Nome")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Função")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Formação")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Carga Horária"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Forma de Contratação"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.projeto.equipes,
                                                      function (equipe, index) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            style:
                                                              index % 2 === 0
                                                                ? "background-color: white"
                                                                : "background-color: var(--v-toolbar-base)",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  equipe.nome
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  equipe.funcao
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  equipe.formacaoExperiencia
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  equipe.cargaHoraria
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  equipe.formaContratacaooStr
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2361346219
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "5" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(5)
                                    },
                                  },
                                },
                                [_vm._v("Indicadores")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "5" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Atividade")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Resultados")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Indicadores"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Meios de Verificação"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Período de Verificação"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.projeto.indicadores,
                                                      function (
                                                        indicador,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            style:
                                                              index % 2 === 0
                                                                ? "background-color: white"
                                                                : "background-color: var(--v-toolbar-base)",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indicador.acao
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indicador.resultados
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indicador.indicadores
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indicador.meiosVerificacao
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indicador.periodoVerificacao
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3546689347
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(6)
                                    },
                                  },
                                },
                                [_vm._v("Divulgação")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "6" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Objetivo Comunicação"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Público Interesse"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Estrategias"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Mídia")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Quantidade")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Período")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.projeto.comunicacoes,
                                                      function (
                                                        comunicacao,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            style:
                                                              index % 2 === 0
                                                                ? "background-color: white"
                                                                : "background-color: var(--v-toolbar-base)",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.objetivoComunicacao
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.publicoInteresse
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.estrategias
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.midia
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.quantidade
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comunicacao.periodo
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3424572514
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "7" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(7)
                                    },
                                  },
                                },
                                [_vm._v("Orçamento")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "7" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("##")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Descrição")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Data Inicio"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Data Término"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Total Estimado"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Natureza")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.orcamentos,
                                                      function (
                                                        orcamento,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.id
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.subId
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.descricao
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.dataInicio.toDateDDMMYYYY()
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.dataTermino.toDateDDMMYYYY()
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.totalEstimado.toDecimal(
                                                                      2
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  orcamento.subId ==
                                                                  "-"
                                                                    ? "background: var(--v-toolbar-base); font-weight: bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    orcamento.natureza
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c("tfoot", [
                                                    _c("tr", [
                                                      _c("td"),
                                                      _c("td"),
                                                      _c("td"),
                                                      _c("td"),
                                                      _c("td"),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.totalOrcamentos.toDecimal(
                                                                2
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("td"),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          610809176
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(8)
                                    },
                                  },
                                },
                                [_vm._v("Parceiros")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "8" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Nome")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Tipo Contribuição"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Valor")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.projeto.parceiros,
                                                      function (
                                                        parceiro,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            style:
                                                              index % 2 === 0
                                                                ? "background-color: white"
                                                                : "background-color: var(--v-toolbar-base)",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parceiro.nome
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parceiro.tipoContribuicaoStr
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parceiro
                                                                    ? parceiro.valor.toMoeda()
                                                                    : Number(
                                                                        0
                                                                      ).toMoeda()
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3543962908
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "9" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(9)
                                    },
                                  },
                                },
                                [_vm._v("Documentos")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "9" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c("v-simple-table", {
                                        staticClass: "table",
                                        staticStyle: { width: "100%" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "var(--v-primary-base)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("#")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Tipo Documento"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("Nome")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.projeto.documentos,
                                                      function (
                                                        documento,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            style:
                                                              index % 2 === 0
                                                                ? "background-color: white"
                                                                : "background-color: var(--v-toolbar-base)",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  documento.tipoDocumento
                                                                    ? documento
                                                                        .tipoDocumento
                                                                        .nome
                                                                    : ""
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  color: "blue",
                                                                  "text-decoration":
                                                                    "underline",
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.BaixarDocumento(
                                                                        documento
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    documento
                                                                      ? documento.nome
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3106436323
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-step",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { step: "10" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.JumpTo(10)
                                    },
                                  },
                                },
                                [_vm._v("Vídeo")]
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticClass: "py-0 px-4 ma-0",
                                  attrs: { step: "10" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Link")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-2 pa-4",
                                      staticStyle: {
                                        background: "var(--v-toolbar-base)",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-4",
                                          on: {
                                            click: function ($event) {
                                              return _vm.AbrirLink(
                                                _vm.projeto.video
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-web")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.projeto.video))]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "background-color": "var(--v-toolbar-base)",
                        display: "flex",
                        "align-items": "center",
                      },
                      attrs: { cols: "12", sm: "12", md: "6", lg: "5" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-2" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "18px",
                                },
                              },
                              [_vm._v("Organização: ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "16px" } },
                              [_vm._v(_vm._s(_vm.ong.nome))]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "18px",
                                },
                              },
                              [_vm._v("Projeto: ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "16px" } },
                              [_vm._v(_vm._s(_vm.projeto.nome))]
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "justify-content": "center",
                                              },
                                              attrs: { cols: "12", sm: "4" },
                                            },
                                            [
                                              _c("avatar-component", {
                                                attrs: {
                                                  src: _vm.app.usuarioFoto,
                                                  nome: _vm.app.login,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                "justify-content": "center",
                                              },
                                              attrs: { cols: "12", sm: "8" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v("Nome: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "normal",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.app.login)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v("E-mail: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "normal",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.app.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 mb-4", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("v-simple-table", {
                                    staticClass: "table",
                                    attrs: { dense: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    "background-color":
                                                      "var(--v-primary-base)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("#")]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("Nome")]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("Nota")]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("Peso")]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.item.itens,
                                                function (criterio, index) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: index,
                                                      style:
                                                        index % 2 === 0
                                                          ? "background-color: white"
                                                          : "background-color: var(--v-toolbar-base)",
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            criterio.criterio
                                                              ? criterio
                                                                  .criterio.nome
                                                              : ""
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-rating", {
                                                            attrs: {
                                                              "background-color":
                                                                "amber lighten-3",
                                                              color: "amber",
                                                              clearable: "",
                                                              readonly:
                                                                _vm.item
                                                                  .veredito !=
                                                                null,
                                                              small: "",
                                                            },
                                                            model: {
                                                              value:
                                                                criterio.nota,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    criterio,
                                                                    "nota",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "criterio.nota",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            criterio.criterio
                                                              ? criterio
                                                                  .criterio.peso
                                                              : ""
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                background: "var(--v-cardBackground-base)",
                                "border-radius": "10px",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Comentários",
                                  dense: "",
                                  outlined: "",
                                  readonly: _vm.item.veredito != null,
                                },
                                model: {
                                  value: _vm.item.comentario,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "comentario", $$v)
                                  },
                                  expression: "item.comentario",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.item.veredito != null
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        type:
                                          _vm.item.veredito == "1"
                                            ? "success"
                                            : "error",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.item.veredito == "1"
                                            ? "Aprovado! O projeto foi aprovado com sucesso."
                                            : "Rejeitado! O projeto foi rejeitado com sucesso."
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    color: "success",
                                    disabled: _vm.item.veredito != null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Aprovar()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-check")]),
                                  _c("span", [_vm._v("Aprovar")]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "error",
                                    disabled: _vm.item.veredito != null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Rejeitar()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-close")]),
                                  _c("span", [_vm._v("Rejeitar")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "primary white--text", height: "70" } },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { color: "white" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("v-img", {
            staticStyle: { "margin-left": "15px" },
            attrs: {
              src: require("../../assets/images/Marca_Bliks_branco.png"),
              contain: "",
              "max-width": "65",
              eager: "",
            },
          }),
          _c("v-img", {
            staticStyle: { "margin-left": "15px" },
            attrs: {
              src: "data:image/jpeg;base64," + _vm.app.tenantLogo,
              contain: "",
              "max-width": "65",
              eager: "",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "", transition: "slide-y-transition" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "mr-2",
                            staticStyle: { "border-radius": "10px" },
                            attrs: {
                              color: "secondary",
                              fab: "",
                              small: "",
                              depressed: "",
                            },
                          },
                          on
                        ),
                        [
                          _c("avatar-component", {
                            attrs: {
                              src: _vm.app.usuarioFoto,
                              nome: _vm.app.login,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                { staticClass: "menuList" },
                [
                  _c(
                    "div",
                    { staticClass: "user" },
                    [
                      _c("avatar-component", {
                        attrs: {
                          src: _vm.app.usuarioFoto,
                          nome: _vm.app.login,
                        },
                      }),
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _c("span", { staticStyle: { "font-size": "15px" } }, [
                          _vm._v(_vm._s(_vm.app.login)),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _c("span", { staticStyle: { "font-size": "12px" } }, [
                          _vm._v(_vm._s(_vm.app.email)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.MudarTema()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(
                                _vm.temaEscuro
                                  ? "mdi-invert-colors-off"
                                  : "mdi-invert-colors"
                              ) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.temaEscuro ? "Tema Claro" : "Tema Escuro")
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogAlterarSenha = true
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-lock-reset")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Alterar Senha")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.Logout()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Sair")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pb-12 pt-10 mt-12", attrs: { fluid: "" } },
        [
          _vm.icone
            ? _c(
                "div",
                { staticClass: "d-flex mb-3" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex pl-3" },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "elevation-2",
                          attrs: { color: "white", size: "50" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "30", color: "primary" } },
                            [_vm._v(_vm._s(_vm.icone))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.subtitulo
                    ? _c("div", { staticClass: "pl-3" }, [
                        _c("h2", { staticClass: "font-weight-medium" }, [
                          _vm._v(_vm._s(_vm.titulo)),
                        ]),
                        _c("small", [_vm._v(_vm._s(_vm.subtitulo))]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "pl-3",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("h2", { staticClass: "font-weight-medium" }, [
                            _vm._v(_vm._s(_vm.titulo)),
                          ]),
                        ]
                      ),
                  _c("v-spacer"),
                  _vm._t("button"),
                ],
                2
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            fixed: "",
            temporary: "",
            width: _vm.$vuetify.breakpoint.smAndDown ? "70vw" : "300px",
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-list-item",
                    {
                      staticStyle: { "padding-left": "30px" },
                      on: {
                        click: function ($event) {
                          return _vm.Logout()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Sair")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        "margin-bottom": "5px",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(
                          " Versão - " + _vm._s(_vm.GetAppVersion()) + " "
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c("v-subheader", { staticClass: "ml-2 mt-1" }, [_vm._v("MENU")]),
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    {
                      staticStyle: {
                        "padding-left": "10px",
                        "border-radius": "10px",
                      },
                      attrs: { to: "/home" },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-home")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.app.ongId ? "Editais Abertos" : "Home")
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.app.perfilId == 1 && _vm.app.empresaId
                ? _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-ballot-outline" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Cadastro")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4044310234
                      ),
                    },
                    _vm._l(_vm.geralMenuButtons, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { padding: "2px 10px 2px 10px" },
                        },
                        [
                          item.restrito
                            ? _c(
                                "v-list-item",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "border-radius": "10px",
                                  },
                                  attrs: { to: item.path },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.app.empresaId
                ? _c(
                    "v-list-group",
                    {
                      attrs: {
                        "prepend-icon": "mdi-text-box-multiple-outline",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Avaliação")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4238914814
                      ),
                    },
                    _vm._l(_vm.avaliacaoMenuButtons, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { padding: "2px 10px 2px 10px" },
                        },
                        [
                          item.restrito
                            ? _c(
                                "v-list-item",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "border-radius": "10px",
                                  },
                                  attrs: { to: item.path },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.app.perfilId == 1 && _vm.app.empresaId
                ? _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-account-cash-outline" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v("Prestação de Contas"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3471132616
                      ),
                    },
                    _vm._l(
                      _vm.prestacaoContasMenuButtons,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { padding: "2px 10px 2px 10px" },
                          },
                          [
                            item.restrito
                              ? _c(
                                  "v-list-item",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "border-radius": "10px",
                                    },
                                    attrs: { to: item.path },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.icon)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.app.perfilId == 1 && _vm.app.empresaId
                ? _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-monitor-eye" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v("Monitoramento"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1658640631
                      ),
                    },
                    _vm._l(
                      _vm.monitoramentoMenuButtons,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { padding: "2px 10px 2px 10px" },
                          },
                          [
                            item.restrito
                              ? _c(
                                  "v-list-item",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "border-radius": "10px",
                                    },
                                    attrs: { to: item.path },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.icon)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.app.perfilId == 1 && _vm.app.empresaId
                ? _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-cog" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Auxiliar")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2798794068
                      ),
                    },
                    _vm._l(_vm.auxiliarMenuButtons, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { padding: "2px 10px 2px 10px" },
                        },
                        [
                          item.restrito
                            ? _c(
                                "v-list-item",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "border-radius": "10px",
                                  },
                                  attrs: { to: item.path },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.app.ongId
                ? _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-chart-areaspline" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Projeto")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1611237822
                      ),
                    },
                    _vm._l(_vm.projetoMenuButtons, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { padding: "2px 10px 2px 10px" },
                        },
                        [
                          item.restrito
                            ? _c(
                                "v-list-item",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "border-radius": "10px",
                                  },
                                  attrs: { to: item.path },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("alterar-senha", {
        on: {
          fechou: function ($event) {
            _vm.dialogAlterarSenha = false
          },
        },
        model: {
          value: _vm.dialogAlterarSenha,
          callback: function ($$v) {
            _vm.dialogAlterarSenha = $$v
          },
          expression: "dialogAlterarSenha",
        },
      }),
      _c(
        "v-footer",
        { attrs: { color: "primary", app: "" } },
        [
          _c(
            "div",
            {
              staticClass: "body-2 white--text text-truncate",
              staticStyle: { width: "65vw" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "padSize",
                  staticStyle: { "font-size": "12px" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.app.empresaId > 0 ? _vm.app.empresa : _vm.app.ong
                    )
                  ),
                ]
              ),
            ]
          ),
          _c("v-spacer"),
          _c("div", { staticClass: "font-weight-regular body-2 white--text" }, [
            _c(
              "span",
              { staticClass: "padSize", staticStyle: { "font-size": "12px" } },
              [_vm._v("© " + _vm._s(new Date().getFullYear()))]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "./AlertService";
import { ArquivoService } from "./ArquivoService";
import { AutenticadorService } from "./AutenticadorService";

export{
    AlertSimple,
    AlertSimpleRes,
    AlertSimpleErr,
    AlertExcludeQuestion,
    ArquivoService,
    AutenticadorService
}
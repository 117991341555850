var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-avatar", { staticClass: "avatar" }, [
    _vm.src
      ? _c("img", { attrs: { src: "data:image/jpeg;base64," + _vm.src } })
      : _c("span", { staticStyle: { color: "white", "font-size": "20px" } }, [
          _vm._v(_vm._s(_vm.iniciais)),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", { staticClass: "fundo" }, [
    _c(
      "div",
      { staticClass: "page" },
      [
        _c(
          "v-app-bar",
          {
            staticClass: "d-print-none",
            attrs: { fixed: "", dark: "", flat: "", color: "primary" },
          },
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "", dark: "" },
                on: {
                  click: function ($event) {
                    return _vm.Fechar()
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
            _c("v-toolbar-title", [_vm._v(_vm._s(_vm.titulo))]),
            _c("v-spacer"),
            _vm.fonteVariavel
              ? _c(
                  "div",
                  { staticClass: "mx-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.AumentarFonte()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-format-font-size-increase")])],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.DiminuirFonte()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-format-font-size-decrease")])],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._t("action"),
            _vm.exportarExcel
              ? _c(
                  "v-toolbar-items",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { dark: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.ExportarExcel("tabela")
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-file-excel-outline"),
                        ]),
                        _vm._v("Exportar (Excel)"),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-toolbar-items",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { dark: "", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.Imprimir()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-printer"),
                    ]),
                    _vm._v("Imprimir"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "v-card",
          { staticClass: "subpage", attrs: { flat: "" } },
          [
            _vm.isEmpresa
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      {
                        style: _vm.$vuetify.breakpoint.smAndDown
                          ? "height: 125px"
                          : "height: 85px",
                      },
                      [
                        _c("v-col", { attrs: { sm: "6" } }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: require("@/assets/images/Bliks_colorido.png"),
                            },
                          }),
                        ]),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            staticStyle: { "font-size": "80%" },
                            attrs: { sm: "6" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text-uppercase font-weight-bold",
                              },
                              [_vm._v(_vm._s(_vm.empresa.nomeCnpj))]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-bold text-uppercase text-caption",
                              },
                              [_vm._v(_vm._s(_vm.hoje))]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "mb-4", attrs: { sm: "6" } },
                          [
                            _c("img", {
                              staticClass: "logo",
                              attrs: {
                                src: require("@/assets/images/Bliks_colorido.png"),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
            _c("hr", { staticClass: "solid" }),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-center", attrs: { cols: "12" } },
                  [
                    _c(
                      "span",
                      { staticClass: "text-uppercase font-weight-bold" },
                      [_vm._v(_vm._s(_vm.titulo))]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("hr", { staticClass: "solid" }),
            _c("div", [_vm._t("default")], 2),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
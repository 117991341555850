var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-2",
      staticStyle: { "border-radius": "15px" },
      attrs: {
        "min-height": "280px",
        "max-width": "330px",
        color: "cardHomeBackground",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          _vm.foto
            ? _c("v-img", {
                attrs: {
                  height: "120",
                  src: "data:image/jpeg;base64," + _vm.foto,
                  contain: "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card-title",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(_vm._s(_vm.nome)),
          ]),
        ]
      ),
      _c(
        "v-card-text",
        {
          staticClass: "py-0",
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "flex-start",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  _vm.show = !_vm.show
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-truncate mr-4",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v(_vm._s(_vm.descricao))]
              ),
              !_vm.show
                ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                : _c("v-icon", [_vm._v("mdi-chevron-up")]),
            ],
            1
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
              },
              [
                _c("v-divider", { staticClass: "my-2" }),
                _c("span", [_vm._v(_vm._s(_vm.descricao))]),
                _c("v-divider", { staticClass: "my-1" }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "mt-2",
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "center",
                "align-items": "flex-start",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("Prazo de Inscrição")]
                      ),
                      _c("v-divider", { staticClass: "my-1" }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-chip", { attrs: { small: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInicio
                                    ? _vm.dataInicio.toDateTimeDDMMYYYY()
                                    : ""
                                )
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-chip", { attrs: { small: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataFim
                                    ? _vm.dataFim.toDateTimeDDMMYYYY()
                                    : ""
                                )
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-2 text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "var(--v-secondary-base)",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _vm._v(
                            " Faltam: " +
                              _vm._s(_vm.dias) +
                              "Dia(s) " +
                              _vm._s(_vm.horas) +
                              "h" +
                              _vm._s(_vm.minutos) +
                              "m" +
                              _vm._s(_vm.segundos) +
                              "s "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "py-0 mt-3",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [_vm._t("actions")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
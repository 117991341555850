import { render, staticRenderFns } from "./Cadastro.vue?vue&type=template&id=6e05f7e2&scoped=true&"
import script from "./Cadastro.vue?vue&type=script&lang=ts&"
export * from "./Cadastro.vue?vue&type=script&lang=ts&"
import style0 from "./Cadastro.vue?vue&type=style&index=0&id=6e05f7e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e05f7e2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VCardTitle,VCol,VDialog,VIcon,VOverlay,VProgressCircular,VRating,VRow,VSimpleTable,VSpacer,VStepper,VStepperContent,VStepperStep,VTextarea,VToolbar,VToolbarItems,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/Users/renan/Fontes/OngFacil/Edital-Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e05f7e2')) {
      api.createRecord('6e05f7e2', component.options)
    } else {
      api.reload('6e05f7e2', component.options)
    }
    module.hot.accept("./Cadastro.vue?vue&type=template&id=6e05f7e2&scoped=true&", function () {
      api.rerender('6e05f7e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/geral/avaliarprojeto/Cadastro.vue"
export default component.exports
import { AreaAtuacaoService } from "./AreaAtuacaoService";
import { BairroComunidadeService } from "./BairroComunidadeService";
import { CriterioService } from "./CriterioService";
import { EditalService } from "./EditalService";
import { EmpreendedorService } from "./EmpreendedorService";
import { EmpresaService } from "./EmpresaService";
import { EnderecoService } from "./EnderecoService";
import { GrupoService } from "./GrupoService";
import { IncentivoService } from "./IncentivoService";
import { NaturezaService } from "./NaturezaService";
import { PerfilUsuarioEnumService } from "./PerfilUsuarioEnumService";
import { ProjetoAvaliacaoService } from "./ProjetoAvaliacaoService";
import { ProjetoService } from "./ProjetoService";
import { PublicoAlvoService } from "./PublicoAlvoService";
import { TipoDocumentoService } from "./TipoDocumentoService";
import { TipoEditalCampoEnumService } from "./TipoEditalCampoEnumService";
import { UsuarioService } from "./UsuarioService";

export{
    AreaAtuacaoService,
    BairroComunidadeService,
    CriterioService,
    EditalService,
    EmpresaService,
    EmpreendedorService,
    EnderecoService,
    GrupoService,
    IncentivoService,
    NaturezaService,
    ProjetoAvaliacaoService,
    ProjetoService,
    PublicoAlvoService,
    TipoDocumentoService,
    UsuarioService,
    //ENUMERADOS
    PerfilUsuarioEnumService,
    TipoEditalCampoEnumService
}
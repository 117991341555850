import { AreaAtuacao, Edital } from ".";

export class EditalAreaAtuacao{

    editalId: number = 0;
    edital!: Edital;
    areaAtuacaoId: number = 0;
    areaAtuacao!: AreaAtuacao;

    constructor(model?: EditalAreaAtuacao){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.areaAtuacaoId = model.areaAtuacaoId;
        this.areaAtuacao = model.areaAtuacao;
    }
}
import { Criterio, ProjetoAvaliacao } from ".";

export class ProjetoAvaliacaoItem{

    id: number = 0;
    avaliacaoId: number = 0;
    avaliacao!: ProjetoAvaliacao;
    criterioId: number = 0;
    criterio!: Criterio;
    nota: number = 0;

    constructor(model?: ProjetoAvaliacaoItem){

        if(!model)
            return;

        this.id = model.id;
        this.avaliacaoId = model.avaliacaoId;
        this.avaliacao = model.avaliacao;
        this.criterioId = model.criterioId;
        this.criterio = model.criterio;
        this.nota = model.nota;
    }
}
